
import "./index.sass";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from "firebase/app";
import { ThemeProvider } from "./Themecontext/themeContext";
// import * as serviceWorker from 'serviceWorker';

const firebaseConfig = {
    apiKey: "AIzaSyA_7QFv0yb5jvhSNKq601ao9l0vw-OVWBI",
    authDomain: "astute-binder-298616.firebaseapp.com",
    databaseURL: "https://astute-binder-298616-default-rtdb.firebaseio.com",
    projectId: "astute-binder-298616",
    storageBucket: "astute-binder-298616.appspot.com",
    messagingSenderId: "973936287275",
    appId: "1:973936287275:web:7f53859f66720634ef546e",
    measurementId: "G-BXY506FQT9",
};
firebase.initializeApp(firebaseConfig);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <ThemeProvider>
        <App />
    </ThemeProvider>
);
// ReactDOM.render(
//     <React.StrictMode>
//         <ThemeProvider>
//             <App />
//         </ThemeProvider>
//     </React.StrictMode>,
//     document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
