import React, { useContext, useState } from 'react';


let ThemeContext = React.createContext(false);
const ThemeUpdateContext = React.createContext(() => {});

export function ThemeProvider( {children}: any) {
    const getThemeContext = localStorage.getItem('theme_context') === "true";
    const [darkTheme, setDarkTheme] = useState(true);

    function toggleTheme() {
        localStorage.setItem('theme_context', (!darkTheme).toString());
        setDarkTheme(prevDarkTheme => !prevDarkTheme);
    }

    return (
        <ThemeContext.Provider value={darkTheme} >
            <ThemeUpdateContext.Provider value={toggleTheme}>
                {children}
            </ThemeUpdateContext.Provider>
        </ThemeContext.Provider>
    )
}

export function useTheme(systemTheme: boolean) {
    ThemeContext = React.createContext(systemTheme);
    return useContext(ThemeContext);
}

export function useThemeUpdate() {
    return useContext(ThemeUpdateContext);
}

