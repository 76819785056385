import { motion } from "framer-motion";
import React from "react";
// import Viewer from "react-viewer";
import { Helper } from "../../helper/helper";
import "./PhotoGallery.sass";
import Skeleton from "@mui/material/Skeleton";
import "./Card.sass";
import Card from "./Card";
import firebase from "firebase";
import "firebase/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Button, InputRef, Tooltip, Upload, UploadFile } from "antd";
import { AuthContext } from "../loginContainer/AuthComponent";

type PhotoGalleryState = {
  showViewer: boolean;
  photoIndex: number;
  imagesData: any;
  loading: boolean;
  imageLoaded: boolean;
  editMode: boolean;
};

class PhotoGallery extends React.Component<any, PhotoGalleryState> {
  helper: Helper;
  photosObj: any;
  Skeletonarray: number[];
  urls: any;
  categoryDetails: any;
  storageRef = firebase.storage().ref();
  // categoryRef: any;
  fileInputRefImages = React.createRef<InputRef | null>();
  rowGalleryTitleRef = React.createRef<HTMLHeadingElement>();
  constructor(props: any) {
    super(props);
    // console.log(props);
    this.helper = new Helper();
    this.state = {
      showViewer: false,
      photoIndex: 0,
      imagesData: {},
      loading: true,
      imageLoaded: false,
      editMode: this.props.edit,
    };
    this.photosObj = {};
    this.Skeletonarray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    // this.categoryRef = firebase.database().ref(`PhotoContainer/${this.props.type}`);
  }
  // saveExistingImgDetails = () => {
  //   var photoContainerRef = firebase
  //     .database()
  //     .ref(`PhotoContainer/${this.props.type}/images`);
  //   this.imageArray.forEach((item) => {
  //     const arrayItemObjRef = photoContainerRef.child(
  //       item.imgName.split(".")[0]
  //     );
  //     arrayItemObjRef
  //       .set(item)
  //       .then(() => {
  //         // console.log(
  //           `Object with ID ${item.imgName.split(".")[0]} added successfully to the database.`
  //         );
  //       })
  //       .catch((error: any) => {
  //         console.error(
  //           `Error adding object with ID ${item.imgName.split(".")[0]} to the database:`,
  //           error
  //         );
  //       });
  //   });
  // };

  componentDidMount() {
    // console.log("gallery component mounted again???", this.props.type);
    // this.categoryDetails = this.props.categoryDetails;

    // window.scrollTo({ top: 0, behavior: "smooth" });
    if (Object.keys(this.state.imagesData).length === 0) {
      var photoContainerRef = firebase
        .database()
        .ref(`PhotoContainer/${this.props.type}`);
      photoContainerRef.on("value", (snapshot: any) => {
        const categoryObj = snapshot.val();
        this.categoryDetails = categoryObj;
        this.photosObj = categoryObj.images;
        Object.keys(this.photosObj).forEach((key) => {
          this.photosObj[key]["isActive"] =
            this.photosObj[key]?.isActive === undefined
              ? true
              : this.photosObj[key].isActive;
          this.photosObj[key]["isFeaturePhoto"] =
            categoryObj.bgimg === this.photosObj[key].imgURL;
        });
        // if (!this.props.edit) {
        //   imagesArray = imagesArray.filter((img) => img.isActive === true);
        // }
        this.setState({ imagesData: this.photosObj, loading: false });
      });
    }

    const handleScroll = () => {
      if (this.rowGalleryTitleRef.current) {
        if (window.scrollY > 80) {
          (this.rowGalleryTitleRef.current as HTMLHeadingElement).style.left =
            "21px";
          this.rowGalleryTitleRef.current?.classList.add("scrolled");
        } else {
          this.rowGalleryTitleRef.current?.classList.remove("scrolled");
          (this.rowGalleryTitleRef.current as HTMLHeadingElement).style.left =
            "5vw";
        }
      }
      // console.log('handling scroll!!');
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    // this.urls = this.helper.getUrlsFromStorageCategory(this.props.type);
    // this.urls.pathRef.then((response: any) => {
    //   // console.log(this.urls);
    //   // this.setState({ photosURLs: response, loading: false });
    //   this.imageArray = response.map((url: string, index: number) => {
    //     return {
    //       imgName: this.urls.imgDataArray[index].imgName,
    //       imgURL: url,
    //       alt: this.props.type + this.urls.imgDataArray[index].imgName,
    //       likes: {
    //         totalLikes: 0,
    //         likedBy: [],
    //       },
    //     };
    //   });
    //   // console.log(this.imageArray);
    // this.setState({imagesData: this.imageArray, loading: false})
    // });
  }
  handleHideImage = (imgkey: any) => {
    const imageRef = firebase
      .database()
      .ref(`PhotoContainer/${this.props.type}/images/${imgkey}`);
    this.categoryDetails.images[imgkey].isActive =
      !this.categoryDetails.images[imgkey].isActive;
    this.setState({ imagesData: this.categoryDetails.images, loading: false });

    imageRef
      .set(this.state.imagesData[imgkey])
      .then(() => {
        console.log(
          `Image ${
            this.state.imagesData[imgkey].imgName.split(".")[0]
          } is hidden successfully.`
        );
      })
      .catch((error: any) => {
        console.log("Error updating the photo hide status!!", error);
      });
  };

  makeCategoryThumbnail = (imageDetails: any) => {
    this.setState({ imageLoaded: false });
    const categoryRef = firebase
      .database()
      .ref(`PhotoContainer/${this.props.type}`);
    this.categoryDetails.bgimg = imageDetails.imgURL;
    categoryRef.set(this.categoryDetails);
  };

  uploadAction = async ({ file, onSuccess, onError }: any) => {
    // console.log(file);
    // setSelectedImage(file);
    try {
      await this.uploadFileToFirebase(file);
      onSuccess("", file);
      // setFileList([...fileList, file]);
    } catch (error) {
      onError(error);
      console.error("Upload failed:", error);
    }
  };

  uploadFileToFirebase = (file: UploadFile) => {
    return new Promise<void>((resolve, reject) => {
      var folderRef = this.storageRef.child(`Images/${this.props.type}`);
      var ref = folderRef.child(`${file.uid}`);
      ref.put(file).then(
        (snapshot: any) => {
          // // console.log(snapshot);
          // // console.log("Uploaded a blob or file!");
          snapshot.ref.getDownloadURL().then((downloadURL: any) => {
            const image = {
              alt: file.name,
              imgName: file.name,
              imgURL: downloadURL,
              isActive: false,
              likes: {
                totalLikes: 0,
              },
            };
            this.categoryDetails["images"] = this.categoryDetails.images
              ? this.categoryDetails.images
              : {};
            this.categoryDetails.images[file.uid] = image;
            // console.log(this.categoryDetails);
            this.setState(this.categoryDetails.images);
            const categoryRef = firebase
              .database()
              .ref(`PhotoContainer/${this.props.type}`);
            categoryRef.set(this.categoryDetails);
          });
        },
        (error: any) => {
          // Handle unsuccessful uploads
          // console.log("Error uploading file", error);
        }
      );
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  removeFileHandler = (file: UploadFile) => {
    // console.log(file);
    const categoryRef = firebase
      .database()
      .ref(`PhotoContainer/${this.props.type}`);
    const imageRef = categoryRef.child(`images/${file.uid}`);
    imageRef
      .remove()
      .then(() => {
        // console.log("Image removed successfully");
      })
      .catch((error: any) => {
        console.error("Error removing image:", error);
      });
  };

  editClickHandler = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  render() {
    const container = {
      hidden: { opacity: 1, scale: 0 },
      visible: {
        opacity: 1,
        scale: 1,
        transition: {
          delayChildren: 0.3,
          staggerChildren: 0.2,
          duration: 500,
        },
      },
    };
    return (
      <AuthContext.Consumer>
        {(userDetails) => {
          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="gallery"
            >
              {/* <button onClick={this.saveExistingImgDetails} className="btn btn-primary position-absolute">Save Existing Details</button> */}
              {/* <div
          className="pl-5 pr-5 pt-4 pb-4 text-right"
          style={{ marginTop: "60px", visibility: "hidden" }}
        >
          <h3 className="gallery-title">{this.props.type}</h3>
        </div> */}
              {userDetails?.user &&
                userDetails?.databaseUser.accessList
                  .photosCategoriesEditAccess && (
                  <FontAwesomeIcon
                    onClick={this.editClickHandler}
                    className={`edit-photogallery-icon ${
                      this.state.editMode ? "active" : ""
                    }`}
                    icon={faEdit}
                  />
                )}
              {/* <div className="disclaimer">Disclaimer: You can click on the images to see full size preview. Still working on clickable indicators :P</div> */}
              {this.state.editMode &&
                userDetails?.user &&
                userDetails?.databaseUser.accessList
                  .photosCategoriesEditAccess && (
                  <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1}}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="category-info-container"
                  >
                    <h2 className="category-title">Category</h2>
                    <img
                      className={`category-feature-photo ${
                        this.state.imageLoaded ? "visible" : "hidden"
                      }`}
                      src={this.categoryDetails?.bgimg}
                      alt={this.categoryDetails?.name}
                      onLoad={() => this.setState({ imageLoaded: true })}
                    />
                    <h2 className="category-title name">
                      {this.categoryDetails?.name}
                    </h2>
                    <div className="category-actions">
                      <Upload
                        customRequest={this.uploadAction}
                        // onChange={handleImageChangeAntD}
                        ref={this.fileInputRefImages}
                        listType="picture"
                        multiple
                        onRemove={this.removeFileHandler}
                        // fileList={fileList}
                      >
                        <Button
                          className="add-photos-btn"
                          icon={
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={["fas", "upload"]}
                            ></FontAwesomeIcon>
                          }
                        >
                          Add more photos
                        </Button>
                      </Upload>
                      {/* <button className="add-photos-btn">
                <FontAwesomeIcon icon={["fas", "plus"]}></FontAwesomeIcon>Add
                Photos
              </button> */}
                    </div>
                  </motion.div>
                )}
              <div
                className={`row-gallery-container ${
                  this.state.editMode ? "category-edit-mode" : ""
                }`}
              >
                <h2 ref={this.rowGalleryTitleRef} className="row-gallery-title">
                  {this.props.type}
                </h2>
                <motion.div variants={container} className={`row-gallery`}>
                  {this.state.loading
                    ? this.Skeletonarray.map((skeleton, index) => (
                        <Skeleton
                          key={index}
                          animation="wave"
                          variant="rectangular"
                          className="card skeleton"
                          style={{
                            height: "500px",
                            width:
                              Math.floor(Math.random() * (50 - 20)) + 20 + "vw",
                            margin: "15px",
                          }}
                        />
                      ))
                    : this.state.imagesData &&
                      Object.keys(this.state.imagesData).map((key, index) => {
                        return (
                          (this.state.editMode ||
                            this.state.imagesData[key].isActive) && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              variants={container}
                              key={this.state.imagesData[key].imgName}
                              className={`photo-card-container`}
                            >
                              {this.state.editMode &&
                                userDetails?.user &&
                                userDetails?.databaseUser.accessList
                                  .photosCategoriesEditAccess && (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 1 }}
                                    className="category-edit-actions"
                                  >
                                    <Tooltip
                                      title={
                                        this.state.imagesData[key].isActive
                                          ? "Hide Photo"
                                          : "Show Photo"
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className={`hide-image-btn ${
                                          this.state.imagesData[key].isActive
                                            ? "btn-hide"
                                            : "btn-show"
                                        }`}
                                        onClick={() =>
                                          this.handleHideImage(key)
                                        }
                                        title="Hide/Show Category"
                                        icon={
                                          this.state.imagesData[key].isActive
                                            ? faEye
                                            : faEyeSlash
                                        }
                                      ></FontAwesomeIcon>
                                    </Tooltip>
                                    <Tooltip title="Make category feature photo">
                                      <FontAwesomeIcon
                                        className="make-category-thumb"
                                        onClick={() =>
                                          this.makeCategoryThumbnail(
                                            this.state.imagesData[key]
                                          )
                                        }
                                        title="Make Category Thumbnail"
                                        icon={faStar}
                                      ></FontAwesomeIcon>
                                    </Tooltip>
                                    {this.state.imagesData[key]
                                      .isFeaturePhoto && (
                                      <FontAwesomeIcon
                                        className="feature-photo"
                                        title="Category Feature Photo"
                                        icon={faStar}
                                      ></FontAwesomeIcon>
                                    )}
                                  </motion.div>
                                )}
                              <Card
                                wait={300 * index}
                                transitionDuration={0.5}
                                hoverEnabled={false}
                                imgData={this.state.imagesData[key]}
                                type={this.props.type}
                                editMode={
                                  this.state.editMode &&
                                  userDetails?.user &&
                                  userDetails?.databaseUser.accessList
                                    .photosCategoriesEditAccess
                                }
                                imgUID={key}
                              ></Card>
                            </motion.div>
                          )
                        );
                      })}
                </motion.div>
              </div>

              {/* <Viewer
                    zoomSpeed={0.3}
                    activeIndex={this.state.photoIndex}
                    className="photoViewer"
                    minScale={1}
                    rotatable={false}
                    scalable={false}
                    visible={this.state.showViewer}
                    onClose={() => {
                        this.setState({ showViewer: false });
                    }}
                    images={this.imageArray}
                /> */}
              {/* <Footer show={true}></Footer> */}
            </motion.div>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default PhotoGallery;
