import React, { useEffect, useState } from "react";
import "./login.sass";
import firebase from "firebase/app";
import "firebase/auth";

const AuthContext = React.createContext<{user: firebase.User | null, databaseUser: any | null} | null>(null);

// interface credential {
//   oauthAccessToken: string;
//   oauthIdToken: string;
//   providerId: string;
//   signInMethod: string;
// }

const AuthComponent: React.FC = ({ children }) => {
  //   const navigate = useNavigate();
  //   const createOrGetUsers = (response: CredentialResponse) => {
  //     const decoded = jwt_decode(response.credential as string);
  //     console.log(decoded);
  //     localStorage.setItem("user", JSON.stringify(decoded));
  //     const { name, picture, sub }: any = decoded;
  //     const user = {
  //       _id: sub,
  //       _type: "user",
  //       userName: name,
  //       image: picture,
  //     };
  //   };

  // const [user, setUser] = useState<any | null>(null);

  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged((currentUser: any) => {
  //     let accessList;
  //     firebase
  //       .database()
  //       .ref(`users/${user?.uid}`)
  //       .on("value", (snapshot: any) => {
  //         accessList = snapshot.val().accessList;
  //         if (currentUser) {
  //           currentUser["accessList"] = accessList;
  //         }
  //         setUser(currentUser);
  //       });
  //   });

  // const [user, setUser] = useState<firebase.User | null>(null);
  // const [databaseUser, setDatabaseUesr] = useState(null);
  let user: any, databaseUser: any;
  const [userDetails, setUserDetails] = useState<{user: firebase.User | null, databaseUser: any | null} | null>(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      user = currentUser;

      firebase
        .database()
        .ref(`users/${user?.uid}`)
        .on("value", (snapshot: any) => {
          databaseUser = snapshot.val();
          setUserDetails({
            user, databaseUser
          });
        });
    });
    // Clean up the observer when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={userDetails}>{children}</AuthContext.Provider>
    // <GoogleOAuthProvider
    //   clientId={process.env.REACT_APP_GOOGLE_API_TOKEN as string}
    // >
    //   <div className="d-flex login-content align-items-center">
    //     <div className="">
    //       {/* <img className="rounded-corner-background p-5" src={svg_login} width="50%" alt="logo"></img> */}
    //       <div className="login-button">
    //           {user ? (
    //             <div>
    //               <h2>Welcome, {user.displayName}</h2>
    //               <button className="btn rounded-corner-background logout-button" onClick={handleLogout}>Logout</button>
    //             </div>
    //           ) : (
    //             <button className="btn rounded-corner-background login-button" onClick={handleLogin}>Login with Google</button>
    //           )}
    //           {/* <GoogleLogin
    //             // useOneTap={}
    //             onSuccess={(response) => {
    //               createOrGetUsers(response);
    //             }}
    //             onError={() => {
    //               console.log("Error Occurred");
    //             }}
    //           /> */}
    //       </div>
    //     </div>
    //   </div>
    // </GoogleOAuthProvider>
  );
};

export default AuthComponent;
export { AuthContext };
