import { motion } from 'framer-motion';
import './newBlogPosts.sass';
import React, { useContext, useEffect, useState } from 'react';
import { Helper } from '../../helper/helper';
// import Blogpost from './BlogPost';
import firebase from 'firebase/app';
import 'firebase/database';
import { Divider, Popconfirm } from 'antd';
// import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';
import { NavLink } from 'react-router-dom';
import { FormatDateOptions } from '@formatjs/intl';
import { svg_nodata_dark } from '../../../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../loginContainer/AuthComponent';

const helper = new Helper();
let Skeletonarray: number[] = [1, 2, 3, 4];

interface NewBlogPostsProps {
  user: any;
}

const options: FormatDateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};
const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      duration: 0.7,
    },
  },
};

const NewBlogPosts: React.FC<NewBlogPostsProps> = ({ user }) => {
  const [blogPostsList, setBlogPosts] = useState<any[]>([]);
  const [spinning, setSpinning] = useState(false);
  // const getBlogPosts = () => {

  // };
  useEffect(() => {
    // getBlogPosts logic added to skip rerenders
    getBlogPostsList();
  }, [user]);

  const getBlogPostsList = () => {
    setSpinning(true);

    var blogPostsRef = !user?.uid
      ? firebase.database().ref('blogPosts/')
      : firebase
          .database()
          .ref('blogPosts/')
          .orderByChild('postedByUserId')
          .equalTo(user?.uid as string);
    blogPostsRef.on('value', (snapshot) => {
      const blogPosts = snapshot.val();
      // console.log(blogPosts);
      let blogsArray: any[] = [];
      blogPosts &&
        Object.keys(blogPosts).forEach((key) => {
          blogPosts[key].keyProp = key;
          blogsArray.push(blogPosts[key]);
        });
      blogsArray = helper.sortByDate(blogsArray, 'postTime');
      setBlogPosts(blogsArray);
      setSpinning(false);
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="container-fluid p-0"
    >
      <div className="h-100 row blog-container">
        {!user && (
          <div className="top-container">
            <span className="disclaimer-text">* No pressure</span>
            <div className="img-container">
              <div className="text-blog-headline">
                Read <br />
                Something<span className="asterisk">*</span>
              </div>
              {/* <span className="type">Type</span>
          <span className="OR">OR</span> */}
              {/* <div className="blog-image-illustration">
                            <img className="svg-img" src="../images/svg_writer_dark.svg" alt="illustration, Two people writing a blog"></img>
                        </div> */}
              {/* <div className="pencil hidden">
                        <span className="brand-logo">Its in Focus</span>
                        <div className="pencil__top"></div>
                        <div className="pencil__bottom">
                            <div className="pencil__tip"></div>
                        </div>
                    </div> */}
            </div>
          </div>
        )}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={container}
          className="d-flex blog-posts-container"
        >
          {spinning &&
            Skeletonarray.map((item, index) => (
              <React.Fragment key={'blog-link-skeleton' + index}>
                <div
                  className="blog-link-skeleton"
                  key={'blog-link-skeleton' + index}
                >
                  <div className="blog-content-skeleton">
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className="blog-link-title-skeleton"
                    ></Skeleton>
                    <Divider></Divider>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className="blog-link-summary-skeleton"
                    ></Skeleton>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className="blog-link-summary-skeleton"
                    ></Skeleton>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className="blog-link-summary-skeleton"
                    ></Skeleton>
                  </div>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    className="blog-link-image-skeleton"
                  ></Skeleton>
                </div>
                {/* <Divider
                  className=""
                  style={{
                    borderWidth: "2px",
                    width: "20%",
                    minWidth: "unset",
                    margin: "24px auto",
                  }}
                ></Divider> */}
              </React.Fragment>
            ))}
          {blogPostsList && blogPostsList.length > 0 ? (
            blogPostsList.map((blog) => {
              return (
                blog.isActive && (
                  <BlogPost
                    key={'blog' + blog.keyProp}
                    blog={blog}
                    user={user}
                    triggerBlogListUpdate={getBlogPostsList}
                  ></BlogPost>
                )
              );
            })
          ) : (
            <div className="no-content-container rounded-corner-background">
              <img
                className="no-content-image"
                src={svg_nodata_dark}
                alt="No content found!!"
              />
              <span className="no-content-text">No content found !!</span>
            </div>
          )}
        </motion.div>
      </div>
      {/* <Footer show={true}></Footer> */}
    </motion.div>
  );
};

const BlogPost = ({ blog, user, triggerBlogListUpdate }: any) => {
  const userDetails = useContext(AuthContext);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDeleteBlog = async () => {
    setOpenDeletePopup(false);
    setLoading(true);
    await firebase
      .database()
      .ref('blogPosts/' + blog.keyProp)
      .remove();
    setLoading(false);
    triggerBlogListUpdate();
  };

  return (
    <React.Fragment key={blog.keyProp}>
      <div key={blog.keyProp} className="blog-link">
        <div className="blog-content">
          <h2 className="blog-link-title">
            <NavLink
              to={'/blogs/' + blog.title}
              state={{
                query: {
                  key: blog.keyProp,
                  keyProp: blog.keyProp,
                  title: blog.title,
                  content: blog.content,
                  image: blog.image,
                  postedBy: blog.postedBy,
                  postedByProfilePicture: blog.postedByProfilePicture,
                  postTime: blog.postTime,
                },
              }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              {blog.title}
            </NavLink>
            {(user || userDetails?.user) &&
              userDetails?.databaseUser?.accessList?.blogsEditAccess && (
                <div className="blog-edit-actions">
                  <NavLink
                    to={'/editor/' + blog.title}
                    state={{
                      query: blog,
                    }}
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }
                  >
                    <FontAwesomeIcon
                      className="blog-edit-icon"
                      icon={faPencilAlt}
                    />
                  </NavLink>
                  <Popconfirm
                    title="Are you sure you want to delete this blog?"
                    open={openDeletePopup}
                    onConfirm={handleDeleteBlog}
                    okButtonProps={{ loading: loading }}
                    onCancel={() => setOpenDeletePopup(false)}
                  >
                    <button
                      className="blog-delete-button"
                      onClick={() => setOpenDeletePopup(true)}
                    >
                      <FontAwesomeIcon
                        className="blog-delete-icon"
                        icon={faTrashAlt}
                      />
                    </button>
                  </Popconfirm>
                </div>
              )}
          </h2>

          <p className="blog-post-summary">
            {/* {blog.image && <img className="blog-link-image" src={blog.image} alt="img-desc"></img>} */}
            {blog.summary}
          </p>
          <span style={{ color: '#1890ff' }}>
            <NavLink
              to={'/blogs/' + blog.title}
              state={{
                query: {
                  key: blog.keyProp,
                  keyProp: blog.keyProp,
                  title: blog.title,
                  content: blog.content,
                  image: blog.image,
                  postedBy: blog.postedBy,
                  postedByProfilePicture: blog.postedByProfilePicture,
                  postTime: blog.postTime,
                },
              }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              ...Continue Reading
            </NavLink>
          </span>
          <div className="post-author-container">
            <span className="blog-post-date">
              {new Date(blog.postTime).toLocaleDateString(undefined, options)}
            </span>
            <span className="username">{blog.postedBy}</span>
            <span className="user-profile-picture">
              <img
                src={blog.postedByProfilePicture}
                alt="User Profile"
                referrerPolicy="no-referrer"
              />
            </span>
          </div>
        </div>
      </div>
      {/* <Divider
                      className=""
                      style={{
                        borderWidth: "2px",
                        width: "20%",
                        minWidth: "unset",
                        margin: "24px auto",
                      }}
                    ></Divider> */}
    </React.Fragment>
  );
};

export default NewBlogPosts;
