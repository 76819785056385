import { motion } from "framer-motion";
import Card from "../pages/Photography/Card";
import firebase from 'firebase/app'
import 'firebase/database';
import 'firebase/storage'
export let photosURLs = {};

// export const categoriesSubject = new Subject();
// export const urlsSubject = new Subject();
// export const photosURLSubject = new Subject();

export class Helper {
    testValue = 0;
    cardPhotosList = [];
    categories = [];
    toProperCase(txt: string | undefined) : any {
        return txt ? txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() : false;
    };
    // getUrlsFromStorage() {
    //     if (!photosURLs.hasOwnProperty("portraits")) {
    //         const categories = ['portraits', 'skies', 'macro', 'irony'];
    //         categories.forEach(category => {
    //             photosURLs[category] = [];
    //             var storage = firebase.storage();
    //             var pathRef = storage.ref('Images/' + category).listAll().then(list => {
    //                 list.items.forEach(itemRef => {
    //                     itemRef.getDownloadURL().then(url => {
    //                         if (photosURLs[category].indexOf(url) === -1) {
    //                             photosURLs[category].push(url);
    //                         }
    //                     })
    //                 });
    //             });
    //         });
    //     }
    // }
    getUrlsFromStorageCategory(category: string) {
        let urlsPromise;
        var storage = firebase.storage();
        var imgDataArray: any[] = [];
        var pathRef = storage.ref('Images/' + category).listAll().then(list => {
            var promiseArr: any[] = [];
            list.items.forEach((id) => {
                imgDataArray.push({
                    imgName: id.name,
                });
                promiseArr.push(id.getDownloadURL());
            });

            urlsPromise = Promise.all(promiseArr).then((resp) => {
                return resp;
            }).catch((error) =>  console.log("Loading failed: " + error));

            return urlsPromise;
        });
         // console.log(pathRef, imgDataArray);

        return {pathRef, imgDataArray};
    }
    changeTestValue() {
        this.testValue = 100;
    }
    sortByDate(arr: any[], prop: string) {
        arr.sort(function (a, b) {
            return Number(new Date(b[prop]).getTime()) - Number(new Date(a[prop]).getTime());
        });
    
        return arr;
    }
}

// const itemList = {
//     hidden: { y: 20, opacity: 0 },
//     visible: {
//         y: 0,
//         opacity: 1
//     }
// }
// export const RenderItems = ({ data, folder, that }: any) => {
//     return data.map((d: any, index: any) =>
//         <motion.div
//             initial="hidden"
//             animate="visible"
//             variants={itemList}
//             key={folder + d + index} onClick={() => { that.setState({ showViewer: true, photoIndex: index }) }}>
//             <Card wait={300 * index} transitionDuration={0.5} hoverEnabled={false} img={folder + d + '.jpg'}></Card>
//         </motion.div>
//     )
// }