import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "./AuthComponent";
import "./login.sass";
import firebase from "firebase/app";
import { svg_welcome, undraw_add_information } from "../../../assets";
import NewBlogPosts from "../blogContainer/newBlogPosts";
import { motion } from "framer-motion";
import { Button, Input, InputRef, Modal, Popconfirm, Upload, UploadFile, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

// interface credential {
//   oauthAccessToken: string;
//   oauthIdToken: string;
//   providerId: string;
//   signInMethod: string;
// }

const Login: React.FC = () => {
  const userDetails = useContext(AuthContext);
  const user = userDetails?.user;
  const [databaseUesr, setDatabaseUesr] = useState<any>(null);
  const [signInLoading, setSignInLoading] = useState(false);

  useEffect(() => {
    const userRef = firebase.database().ref(`users/${user?.uid}`);
    userRef.on("value", (snapshot) => {
      // console.log(snapshot.val());
      setDatabaseUesr(snapshot.val());
    });
  }, [user]);

  const handleLogin = async () => {
    setSignInLoading(true);
    try {
      // Sign in with popup
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result: any) => {
          const user = result.user;
          const databaseUser = {
            name: user?.displayName,
            email: user?.email,
            profile_picture: user?.photoURL,
            // Other user details
          };
          // Save user information to Firebase database
          firebase
            .database()
            .ref(`users/${user?.uid}`)
            .transaction((currentData) => {
              // If the object doesn't exist, set the value
              // console.log(currentData);
              if (!currentData) {
                return Object.assign({}, databaseUser);
              }
              // Object already exists, no changes needed
              return currentData;
            });
          setSignInLoading(false);
        })
        .catch((error: any) => {
          // Handle Errors here.
          // console.log("Error :", { ...error });
          setSignInLoading(false);
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  return (
    <motion.div
      className="login-page-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {user ? (
        <div className="loggedin-content">
          <div className="profile-info">
            <img
              className="welcome_image"
              src={undraw_add_information}
              alt="welcome"
            ></img>

            {/* <img
              className="user-profile-picture"
              src={user.photoURL ? user.photoURL : ""}
              alt="user"
            ></img> */}
            {/* <h1>{user.displayName}</h1> */}
            <h1>Your Profile<span className="color-primary"><br></br>&</span> Content</h1>
            <button className="btn logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
          {databaseUesr?.accessList?.photosCategoriesEditAccess && (
            <AddPhotosCategories databaseUesr></AddPhotosCategories>
          )}
          <div className="blogs-container rounded-corner-background">
            <h2 className="blogs-title">Blogs</h2>
            <NewBlogPosts user={user} />
          </div>
        </div>
      ) : (
        <div className="login-content">
          <img className="welcome_image" src={svg_welcome} alt="welcome"></img>
          {/* <button className="btn rounded-corner-background login-button" onClick={handleLogin}>Login with Google</button> */}
          <Button
            className="login-button"
            icon={<FontAwesomeIcon icon={faGoogle} />}
            loading={signInLoading}
            onClick={() => handleLogin()}
          >
            Sign in with Google
          </Button>
        </div>
      )}
    </motion.div>
  );
};

export default Login;

const AddPhotosCategories = (databaseUesr: any) => {
  // console.log(databaseUesr);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [categoryAlreadyExists, setCategoryAlreadyExists] = useState(false);
  const [categoryActions, setCategoryActions] = useState({
    add: false,
    delete: false,
    edit: false,
    hide: false,
  });
  const [createNewCategoryModelOpen, setCreateNewCategoryModelOpen] =
    useState(false);
  const [categoryCreationLoading, setCategoryCreationLoading] = useState(false);
  const [deletingCategory, setDeletingCategory] = useState("");
  const [newCategory, setNewCategory] = useState<{
    bgimg: string;
    direction: string;
    images: any;
    isActive: boolean;
    order: number;
  }>({
    bgimg: "",
    direction: "",
    images: {},
    isActive: false,
    order: 0,
  });
  const [categories, setCategories] = useState<any>();
  var storageRef = firebase.storage().ref();
  const fileInputRefbgimg = useRef<InputRef | null>(null);
  const fileInputRefImages = useRef<InputRef | null>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const categoriesRef = firebase.database().ref(`/PhotoContainer`);
    categoriesRef.on("value", (snapshot) => {
      setCategories(snapshot.val());
    });
  }, []);

  const handleInputChange = (
    $event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setCategoryName($event.target.value);
    if (
      Object.keys(categories).find(
        (key) => key === $event.target.value.toLocaleLowerCase()
      )
    ) {
      setCategoryAlreadyExists(true);
    } else {
      setCategoryAlreadyExists(false);
    }
  };

  const uploadFileToFirebase = (file: UploadFile, bgimg: boolean) => {
    return new Promise<void>((resolve, reject) => {
      var folderRef = bgimg
        ? storageRef.child(`PhotoContainer`)
        : storageRef.child(`Images/${categoryName}`);
      var ref = folderRef.child(`${file.uid}`);
      ref.put(file as any).then(
        (snapshot) => {
          // // console.log(snapshot);
          // // console.log("Uploaded a blob or file!");
          snapshot.ref.getDownloadURL().then((downloadURL: any) => {
            if (bgimg) {
              newCategory.bgimg = downloadURL;
              // console.log(newCategory);
              setNewCategory(newCategory);
            } else {
              const image = {
                alt: file.name,
                imgName: file.name,
                imgURL: downloadURL,
                isActive: true,
                isFeaturePhoto: downloadURL === newCategory.bgimg,
                likes: {
                  totalLikes: 0,
                },
              };
              newCategory.images[file.uid] = image;
              // console.log(newCategory);
              setNewCategory(newCategory);
            }
          });
        },
        (error: any) => {
          // Handle unsuccessful uploads
          // console.log("Error uploading file", error);
        }
      );
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  const customRequestHandler = (event: any) => {
    uploadAction(event, false);
  };

  const customRequestHandlerBgimg = (event: any) => {
    uploadAction(event, true);
  };

  const uploadAction = async (
    { file, onSuccess, onError }: any,
    bgimg: boolean
  ) => {
    // console.log(file);
    // setSelectedImage(file);
    try {
      await uploadFileToFirebase(file, bgimg);
      onSuccess("", file);
      setFileList([...fileList, file]);
    } catch (error) {
      onError(error);
      console.error("Upload failed:", error);
    }
  };

  const addNewCategory = () => {
    setSavingInProgress(true);
    const photoContainerRef = firebase
      .database()
      .ref(`PhotoContainer/${categoryName.toLocaleLowerCase()}`);
    // console.log(newCategory);
    photoContainerRef
      .set(newCategory)
      .then(() => {
        const categoriesRef = firebase.database().ref(`/categoryRoutes`);
        const newRoute = {
          categoryName: categoryName,
          categoryOrder: 0
        }
        categoriesRef.push(newRoute);
        setSavingInProgress(false);
        setCreateNewCategoryModelOpen(false);
        clearFields();
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleOk = () => {
    setCategoryCreationLoading(true);
    setTimeout(() => {
      setCreateNewCategoryModelOpen(false);
      setCategoryCreationLoading(false);
      categoryActions.add = false;
      setCategoryActions(categoryActions);
    }, 2000);
  };

  const handleCancel = () => {
    // console.log("Clicked cancel button");
    setCreateNewCategoryModelOpen(false);
    categoryActions.add = false;
    setCategoryActions(categoryActions);
  };

  // const handleDeleteCategory = (key: string) => {
  //   const categoryRef = firebase.database().ref(`PhotoContainer/${key}`);
  //   if (databaseUesr) {
  //     categoryRef
  //       .remove()
  //       .then(() => {
  //         setDeletingCategory("");
  //         // console.log(`${key} category delete!! This action is irreversible.`);
  //       })
  //       .catch((error) => {
  //         // console.log(`Error Deleting the category ${key}, ${error}`);
  //       });
  //   } else {
  //     message.error("You are not authorised to perform this action!!")
  //   }
  // };

  const handleHideCategory = (key: string) => {
    const categoryRef = firebase.database().ref(`PhotoContainer/${key}`);
    categories[key].isActive = !categories[key].isActive;
    categoryRef
      .set(categories[key])
      .then(() => {
        // console.log("Category updated successfully!!");
      })
      .catch((error) => {
        // console.log("Error while updating category", error);
      });
  };

  const handleEditCategory = (key: string) => {
    const data = { categoryDetails: categories[key] };
    navigate(`/photos/${key.toLocaleLowerCase()}/edit`, { state: data });
  };

  const clearFields = () => {
    setCategoryName("");
    setFileList([]); // Reset the fileList to clear the input field
    if (fileInputRefbgimg.current) {
      (fileInputRefbgimg.current as any).fileList.pop(); // Reset the input value directly (if needed)
    }
    if (fileInputRefImages.current) {
      (fileInputRefImages.current as any).fileList.length = 0;
    }
  };

  return (
    <div className="photos-category-creation-container">
      <Modal
        title="Create New Category"
        open={createNewCategoryModelOpen}
        onOk={handleOk}
        confirmLoading={categoryCreationLoading}
        onCancel={handleCancel}
        okText="Create"
        okButtonProps={{
          disabled:
            !categoryName ||
            categoryName === "" ||
            !newCategory.bgimg ||
            newCategory.images.length === 0 ||
            savingInProgress,
          className: "add-category-btn",
          onClick: addNewCategory,
        }}
        wrapClassName="new-category-modal"
        width={800}
      >
        <div className="new-category-container">
          <h1 className="photos-category-creation-title d-none">
            Create New Category
          </h1>
          <div className="category-creation-form">
            <div>
              <h4 className="category-label">Category Title/Type</h4>
              <Input
                placeholder="Enter title here"
                className="title-input"
                value={categoryName}
                onChange={(event) => handleInputChange(event, "title")}
              />
              {categoryAlreadyExists && (
                <span className="error-category-exists">
                  Category already exists!! Please select a different name.
                </span>
              )}
            </div>
            <div className="image-input-container bg">
              <h4 className="category-thumbnail-title">Category Thumbnail</h4>
              <Upload
                customRequest={customRequestHandlerBgimg}
                // onChange={handleImageChangeAntD}
                ref={fileInputRefbgimg}
                listType="picture"
                maxCount={1}
                disabled={
                  !categoryName || categoryName === "" || categoryAlreadyExists
                }
                // fileList={fileList}
              >
                <Button
                  disabled={
                    !categoryName ||
                    categoryName === "" ||
                    categoryAlreadyExists
                  }
                  icon={
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={["fas", "upload"]}
                    ></FontAwesomeIcon>
                  }
                >
                  Upload
                </Button>
              </Upload>
            </div>
            <div className="image-input-container">
              <h4 className="category-images-title">Images</h4>
              <Upload
                customRequest={customRequestHandler}
                // onChange={handleImageChangeAntD}
                ref={fileInputRefImages}
                listType="picture"
                disabled={!newCategory.bgimg}
                multiple={true}
                // fileList={fileList}
              >
                <Button
                  disabled={!newCategory.bgimg}
                  icon={
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={["fas", "upload"]}
                    ></FontAwesomeIcon>
                  }
                >
                  Upload
                </Button>
              </Upload>
            </div>
            <button
              disabled={
                !categoryName ||
                categoryName === "" ||
                !newCategory.bgimg ||
                newCategory.images.length === 0 ||
                savingInProgress
              }
              className="add-category-btn d-none"
              onClick={addNewCategory}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
      <div className="existing-categories-container rounded-corner-background">
        <div className="existing-category-header">
          <h1 className="photos-category-creation-title">Photos Categories</h1>
          <div
            className={`category-actions ${
              deletingCategory ? "filter-blur-5" : ""
            }`}
          >
            <FontAwesomeIcon
              className={`add-category-action ${
                categoryActions.add ? " active" : ""
              }`}
              onClick={() => {
                setCategoryActions({
                  add: !categoryActions.add,
                  delete: false,
                  edit: false,
                  hide: false,
                });
                setCreateNewCategoryModelOpen(true);
              }}
              title="Add Category"
              icon={["fas", "plus"]}
            ></FontAwesomeIcon>
            {/* <FontAwesomeIcon
              className={`delete-category-action ${
                categoryActions.delete ? " active" : ""
              }`}
              onClick={() =>
                setCategoryActions({
                  add: false,
                  delete: !categoryActions.delete,
                  edit: false,
                  hide: false,
                })
              }
              title="Delete Category"
              icon={faTrash}
            ></FontAwesomeIcon> */}
            {/* <FontAwesomeIcon
              className={`hide-category-action ${
                categoryActions.hide ? " active" : ""
              }`}
              onClick={() =>
                setCategoryActions({
                  add: false,
                  delete: false,
                  edit: false,
                  hide: !categoryActions.hide,
                })
              }
              title="Edit Category"
              icon={["fas", "eye-slash"]}
            ></FontAwesomeIcon> */}
            <FontAwesomeIcon
              className={`edit-category-action ${
                categoryActions.edit ? " active" : ""
              }`}
              onClick={() =>
                setCategoryActions({
                  add: false,
                  delete: false,
                  edit: !categoryActions.edit,
                  hide: false,
                })
              }
              title="Edit Category"
              icon={["fas", "edit"]}
            ></FontAwesomeIcon>
          </div>
        </div>
        <div className="existing-categories">
          {categories &&
            Object.keys(categories).map((item: any, index: number) => {
              return (
                <div
                  key={item}
                  className={`existing-category ${categories[item].isActive ? '' : 'inactive'} ${
                    deletingCategory === item
                      ? ""
                      : deletingCategory === ""
                      ? ""
                      : "filter-blur-5"
                  }`}
                >
                  <img
                    className={`${imageLoaded ? "visible" : "hidden"}`}
                    src={categories[item]?.bgimg}
                    alt={categories[item]?.alt}
                    onLoad={() => setImageLoaded(true)}
                  ></img>
                  <span className="category-name">{item}</span>
                  {/* {categoryActions.delete && (
                    <Popconfirm
                      title="Are you sure to delete this category?"
                      onConfirm={() => handleDeleteCategory(item)}
                      onCancel={() => setDeletingCategory("")}
                      onOpenChange={() => setDeletingCategory(item)}
                      overlayClassName="delete-category-popup"
                      okType="danger"
                      okText="Delete"
                      cancelText="Cancel"
                    >
                      <FontAwesomeIcon
                        className="delete-category-btn"
                        title="Delete Category"
                        icon={faTrash}
                      ></FontAwesomeIcon>
                    </Popconfirm>
                  )} */}
                  
                    <FontAwesomeIcon
                      className="hide-category-btn"
                      onClick={() => handleHideCategory(item)}
                      title="Hide/Show Category"
                      icon={categories[item].isActive ? faEye : faEyeSlash}
                    ></FontAwesomeIcon>
                  
                  {categoryActions.edit && (
                    <FontAwesomeIcon
                      className="edit-category-btn"
                      onClick={() => handleEditCategory(item)}
                      title="Edit Category"
                      icon={["fas", "edit"]}
                    ></FontAwesomeIcon>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
