import "./App.sass";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "antd/dist/antd.css";
import loadable from "@loadable/component";
import { useTheme } from "./Themecontext/themeContext";
import Navbar from "./components/Navbar";
import PhotoGallery from "./components/pages/Photography/PhotoGallery";
import Blog from "./components/pages/blogContainer/blog";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faReact,
  faAngular,
  faJsSquare,
  faHtml5,
  faCss3Alt,
  faStripeS,
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCode,
  faDollarSign,
  faFire,
  faSquare,
  faEnvelope,
  faBars,
  faTimes,
  faSun,
  faMoon,
  faMedal,
  faUser,
  faSignOutAlt,
  faSignInAlt,
  faUpload,
  faHeart as fasHeart,
  faPencilAlt,
  faEdit,
  faPlus,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import AuthComponent from "./components/pages/loginContainer/AuthComponent";
import Login from "./components/pages/loginContainer/Login";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import firebase from "firebase";
import "firebase/database";
import MyEditor from "./components/pages/editor/Editor";
library.add(
  faReact,
  faAngular,
  faJsSquare,
  faHtml5,
  faCss3Alt,
  faStripeS,
  faCode,
  faDollarSign,
  faFire,
  faSquare,
  faInstagram,
  faTwitter,
  faLinkedin,
  faEnvelope,
  faBars,
  faTimes,
  faSun,
  faMoon,
  faMedal,
  faUser,
  faSignOutAlt,
  faSignInAlt,
  faUpload,
  faHeart,
  fasHeart,
  faPencilAlt,
  faEdit,
  faPlus,
  faEyeSlash,
  faEye
);

function App(this: any) {
  // const [modal, setModalVisible] = useState(false);
  const [navClass, setnavClass] = useState("home");
  const [categoryRoutes, setCategoryRoutes] = useState<any>();

  const getThemeContext = localStorage.getItem("theme_context") === "true";
  const darkTheme = useTheme(getThemeContext);
  document.body.style.backgroundColor = darkTheme ? "#020402" : "#f1f3f5";

  const LoadableNewBlog = loadable(
    () => import("./components/pages/blogContainer/newBlogPosts")
  );
  const LoadableFooter = loadable(() => import("./components/Footer"));
  const LoadableGear = loadable(() => import("./components/pages/gear/gear"));
  const LoadablePhotoContainer = loadable(
    () => import("./components/pages/Photography/PhotoContainer")
  );
  const LoadableBlogPage = loadable(
    (props: any) => import("./components/pages/blogContainer/blog"),
    {
      render(props: any) {
        <Blog {...props}></Blog>;
      },
    }
  );
  const LoadablePhotoGallery = loadable(
    (props: any) => import("./components/pages/Photography/PhotoGallery"),
    {
      render(props: any) {
        <PhotoGallery {...props}></PhotoGallery>;
      },
    }
  );

  const updateNavClass = () => {
    let navPath = window.location.pathname.split("/").pop();
    setnavClass(navPath ? navPath : "home");
  };

  useEffect(() => {
    const getCategoryRoutes = () => {
      const categoriesRef = firebase.database().ref(`/categoryRoutes`);
      // categoriesRef
        // .orderByChild("isActive")
        // .equalTo(true)
        // .on(
        //   "value",
          categoriesRef.on(
            "value",
          (snapshot: any) => {
            const routes = snapshot.val();
            // setCategoryRoutes(snapshot.val());
            // console.log(routes);
            localStorage.setItem("categoryRoutes", JSON.stringify(routes));
            setCategoryRoutes(routes);
          },
          (error: any) => {
            // console.log("Error getting routes:", error);
          }
        );
    };
    getCategoryRoutes();
  }, []);


  return (
    <AuthComponent>
      <div
        className={
          darkTheme
            ? "main-content-container darkTheme"
            : "main-content-container lightTheme"
        }
      >
        <Router onChange={updateNavClass}>
          {/* <NavbarAntD></NavbarAntD> */}
          <Navbar className={navClass} />
          <div id="butter">
            <AnimatePresence exitBeforeEnter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <LoadablePhotoContainer />
                  }
                />
                {categoryRoutes &&
                  Object.keys(categoryRoutes).length > 0 &&
                  Object.keys(categoryRoutes).map((key) => (
                    <>
                      <Route
                        path={`photos/${categoryRoutes[key].categoryName.toLocaleLowerCase()}`}
                        element={<LoadablePhotoGallery type={categoryRoutes[key].categoryName} 
                        key={categoryRoutes[key].categoryName}
                      />}
                      ></Route>
                      <Route
                        path={`photos/${categoryRoutes[key].categoryName.toLocaleLowerCase()}/edit`}
                        element={<LoadablePhotoGallery type={categoryRoutes[key].categoryName} edit={true}
                        key={categoryRoutes[key].categoryName + 'edit'}
                      />}
                      ></Route>
                    </>
                  ))}
                <Route path="blogs" element={<LoadableNewBlog />}></Route>
                <Route path="login" element={<Login />}></Route>
                <Route
                  path="blogs/:id"
                  element={<LoadableBlogPage></LoadableBlogPage>}
                ></Route>
                <Route
                  path="editor/:id"
                  element={<MyEditor></MyEditor>}
                ></Route>
                {/* <Route path="resume" element={<LoadableResume/>}></Route> */}
                <Route path="gear" element={<LoadableGear />}></Route>
                <Route path="editor" element={<MyEditor />}></Route>
              </Routes>
            </AnimatePresence>
          </div>
          <LoadableFooter show={true}></LoadableFooter>
        </Router>
      </div>
    </AuthComponent>
  );
}

export default App;
