import firebase from "firebase";

export const fetchUser = () => {
    const userInfo = localStorage.getItem('user') !== undefined ? JSON.parse(localStorage.getItem('user') as string) : localStorage.clear();
    return userInfo;
}

export const handleLogin = async () => {
    try {
      // Sign in with popup
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result: { credential: { toJSON: () => any; }; user: any; }) => {
          // The signed-in user info.
          const user = result.user;
          const databaseUser = {
            name: user?.displayName,
            email: user?.email,
            profile_picture: user?.photoURL,
            // Other user details
          };

          // Save user information to Firebase database
          firebase
            .database()
            .ref(`users/${user?.uid}`)
            .transaction((currentData: any) => {
              // If the object doesn't exist, set the value
              // console.log(currentData);
              if (!currentData) {
                return Object.assign({}, databaseUser);
              }
              // Object already exists, no changes needed
              return currentData;
            });
        })
        .catch((error: any) => {
          // Handle Errors here.
          // console.log("Error :", { ...error });
        });
    } catch (error) {
      // console.log(error);
    }
  };

  export const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };