import { Skeleton } from "@mui/material";
import {
  Form,
  message,
  Tooltip,
  Button,
  Divider,
  Comment,
  MessageArgsProps,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import firebase from "firebase/app";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helper } from "../../helper/helper";
import "./blog.sass";
import { FormatDateOptions } from "@formatjs/intl";
import { AuthContext } from "../loginContainer/AuthComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { handleLogin } from "../../helper/fetchUser";

let helper = new Helper();
const options: FormatDateOptions = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const messageConfig: MessageArgsProps = {
  className: "snack-bar-message",
  content: "",
  duration: 3,
};

function Blog(props: any) {
  const userDetails = useContext(AuthContext);
  const user = userDetails?.user;
  const [imageLoaded, setImageLoaded] = useState(false);

  let [commentsArray, setCommentsArray] = useState<any[]>([]);
  let pRef = React.createRef<HTMLDivElement>();
  const location = useLocation();
  let contentpassed = location?.state?.query;
  let keyForComponent = location?.state?.query?.key;
  if (!contentpassed) {
    contentpassed = JSON.parse(localStorage.getItem(location.key) || "");
  }
  localStorage.setItem(location.key, JSON.stringify(contentpassed));
  if (commentsArray.length === 0) {
    getCommentsF(true);
  }
  
  function getCommentsF(reRender?: boolean) {
    var blogPostsRef = firebase
      .database()
      .ref(`blogPosts/${keyForComponent}/comments`);
    blogPostsRef.on("value", (snapshot: any) => {
      const comments = snapshot.val();
      let lcommentsArray: any[] = [];
      comments &&
        Object.keys(comments).forEach((key) => {
          comments[key].keyProp = key;
          lcommentsArray.push(comments[key]);
        });
      lcommentsArray = helper.sortByDate(lcommentsArray, "postTime");
      if (lcommentsArray.length > 0 || !reRender)
        setCommentsArray(lcommentsArray);
    });
  }

  useEffect(() => {}, [commentsArray]);
  const [loading, setButtonLoading] = useState(false);
  const [form] = Form.useForm();
  const writeComment = (post: any) => {
    setButtonLoading(true);
    post.postTime = new Date().getTime();
    firebase
      .database()
      .ref(`blogPosts/${keyForComponent}/comments`)
      .push(post, (error: any) => {
        setButtonLoading(false);
        if (error) {
          messageConfig.content = "Some error occurred!";
          message.error(messageConfig);
          alert("Data could not be saved." + error);
        } else {
          messageConfig.content = "Submitted Successfully";
          message.success(messageConfig);
          onReset();
        }
      });
  };

  const onSubmit = (post: any) => {
    post.postedBy = user?.displayName;
    post.postedByUID = user?.uid;
    post.postedByProfilePicture = user?.photoURL;
    post = JSON.parse(
      JSON.stringify(post, function (k, v) {
        if (v === undefined) {
          return null;
        }
        return v;
      })
    );
    writeComment(post);
  };
  const onReset = () => {
    form.resetFields();
  };

  const deleteComment = (comment: any) => {
    firebase
      .database()
      .ref(`blogPosts/${keyForComponent}/comments`)
      .child(comment.keyProp)
      .remove((error: any) => {
        setButtonLoading(false);
        if (error) {
          messageConfig.content = "Some error occurred!";
          message.error(messageConfig);
          alert("Comment could not be deleted." + error);
        } else {
          messageConfig.content = "Comment deleted successfully";
          message.success(messageConfig);
          getCommentsF(false);
        }
      });
  };

  const formItemLayout: any = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="blog-post-container"
    >
      <section
        key={contentpassed.keyProp}
        className={"blog-post " + contentpassed.keyProp}
      >
        {contentpassed.image && (
          <>
            <Skeleton
              className={!imageLoaded ? "visible" : "d-none"}
              animation="wave"
              variant="rectangular"
            ></Skeleton>
            <img
              src={contentpassed.image}
              className={`blog-header-image ${
                imageLoaded ? "visible" : "hidden"
              }`}
              onLoad={() => setImageLoaded(true)}
              alt={contentpassed.image}
            />
          </>
        )}
        {/* <div className="blog-image-bg">
            </div> */}
        <h3 className="blog-post-title">{contentpassed.title} :</h3>
        <div ref={pRef} className="blog-post-content">
          {/* <span className="blog-post-content"> */}
          {/* {contentpassed.content} */}
          <MyComponent htmlContent={contentpassed.content} />
          {/* </span> */}
          <div className="post-author-details">
            <div className="post-author-texts">
              <span className="username">{contentpassed.postedBy}</span>
              <span className="post-time">
                {new Date(contentpassed.postTime).toLocaleDateString(
                  undefined,
                  options
                )}
              </span>
            </div>
            <span className="user-profile-picture">
              <img
                src={contentpassed.postedByProfilePicture}
                alt="User Profile"
              />
            </span>
          </div>
        </div>
        <div className="comments-container">
          <h5 className="comments-title">Comments : </h5>
          <div className="comments">
            {commentsArray.length > 0 &&
              commentsArray.map((comment) => (
                <Comment
                  key={comment.keyProp}
                  avatar={comment.postedByProfilePicture}
                  // actions={actions}
                  author={<span>{comment.postedBy}</span>}
                  // avatar={
                  //     // <Avatar
                  //     //     src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                  //     //     alt="Han Solo"
                  //     // />
                  // }
                  content={<p>{comment.Content}</p>}
                  datetime={
                    <Tooltip>
                      <span>{new Date(comment.postTime).toDateString()}</span>
                    </Tooltip>
                  }
                  children={
                    user?.uid === comment.postedByUID && (
                      <FontAwesomeIcon
                        title="Delete comment"
                        onClick={() => deleteComment(comment)}
                        className="delete-comment cursor-pointer"
                        icon={faTrashAlt}
                      />
                    )
                  }
                />
              ))}
          </div>
          {user ? (
            <div className="new-comment">
              <Form
                {...formItemLayout}
                layout={formItemLayout}
                form={form}
                initialValues={{
                  layout: formItemLayout,
                }}
                name="control-hooks"
                onFinish={onSubmit}
              >
                {/* {!user && (
                <Form.Item name="Name" rules={[{ required: true }]}>
                  <Input type="Name" placeholder="Name*" />
                </Form.Item>
              )} */}
                {/* <Form.Item name="title" label="Title" rules={[{ required: false, }]}>
                        <Input />
                    </Form.Item> */}
                <Form.Item name="Content">
                  <TextArea placeholder="comment" />
                </Form.Item>
                <Form.Item>
                  <Button
                    className="mr-2 submitbtn"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div className="new-comment login-required mb-5">
              <span className="">
                {" "}
                Please{" "}
                <span onClick={handleLogin} className="login-link">
                  login
                </span>{" "}
                to add comments.
              </span>
            </div>
          )}
        </div>
      </section>
      <Divider
        className=""
        style={{
          borderWidth: "2px",
          width: "50%",
          minWidth: "unset",
          margin: "0px auto",
        }}
      ></Divider>
      {/* <Footer show={true}></Footer> */}
    </motion.div>
  );
}

export default Blog;

interface MyComponentProps {
  htmlContent: string;
}

const MyComponent: React.FC<MyComponentProps> = ({ htmlContent }) => {
  return <div className="" dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
