// import React, { useState, useEffect } from "react";
// import { Link, NavLink } from "react-router-dom";
// import "./Navbar.sass";
// import { Helper } from "./helper/helper";
// import { useTheme, useThemeUpdate } from "../Themecontext/themeContext";
// import { Switch } from "antd";
// import { useLocation } from "react-router";
// import * as $ from "jquery";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// function Navbar(props: any) {
//   const helper = new Helper();
//   let path = helper.toProperCase(window.location.pathname.split("/").pop());
//   const [click, setClick] = useState(false);
//   const [showimg, showImage] = useState(false);
//   const [title, setTitle] = useState(false);
//   const [scrolled, setScrolled] = useState(false);
//   const [compactNavMenu, setCompacNavMenu] = useState(false);
//   const darkTheme = useTheme(window.matchMedia("(prefers-color-scheme: dark)").matches);
//   const getThemeContext = localStorage.getItem("theme_context") === "true";
//   const toggleTheme = useThemeUpdate();
//   const [navClass, updateNavClass] = useState("home");
//   const location = useLocation();
//   useEffect(() => {
//     // console.log("route has been changed");
//     updateNavClass(
//       location.pathname.split("/").pop()
//         ? location.pathname.split("/").pop()
//         : "home"
//     );
//   }, [location.pathname]);

//   const handleClick = () => {
//     if (window.innerWidth < 961) {
//       if (!click) $("body").css({ overflow: "hidden", position: "fixed" });
//       else $("body").css({ overflow: "auto", position: "relative" });
//       setClick(!click);
//     }
//   };
//   // const closeMobileMenu = () => setClick(false);

//   // const showImg = () => {
//   //     if (window.innerWidth <= 960) {
//   //         showImage(true);
//   //     } else {
//   //         showImage(false);
//   //     }
//   // };
//   // useEffect(() => { showImg(); });
//   let count = 0;
//   const compactnavbar = () => {
//     // console.log("compacting navbar :" + ++count);
//     const nav = Array.from(
//       document.getElementsByClassName("navbar") as HTMLCollectionOf<HTMLElement>
//     );
//     const typeTitle = Array.from(
//       document.getElementsByClassName(
//         "photoGalleryTitle"
//       ) as HTMLCollectionOf<HTMLElement>
//     );
//     const footer = document.getElementsByClassName("footer");

//     if (document.documentElement.scrollTop > 80) {
//       if (!nav[0]?.classList.contains("mobilemenu")) setScrolled(true);
//       if (window.innerWidth > 960) setCompacNavMenu(true);
//       if (!nav[0]?.classList.contains("mobilemenu")) {
//         nav[0].classList.add("mobilemenu");
//       }
//     } else {
//       // if (!compactNavMenu) {
//       if (nav[0]?.classList.contains("mobilemenu")) setScrolled(false);
//       if (window.innerWidth > 960) setCompacNavMenu(false);
//       if (nav[0]?.classList.contains("mobilemenu")) {
//         nav[0].classList.remove("mobilemenu");
//       }
//     }
//     if (nav[0]) {
//       if (
//         (footer[0] && footer[0].getBoundingClientRect().top < 10) ||
//         path === "Playaround"
//       ) {
//         nav[0].classList.add("h-0");
//         typeTitle[0].style.left = "-35px";
//       } else {
//         nav[0].classList.remove("h-0");
//         typeTitle[0].style.left = "28px";
//       }
//     }
//   };
//   let timer: any = null;
//   let throttledCompactnavbar = () => {
//     if (timer) {
//       return;
//     }
//     timer = setTimeout(() => {
//       compactnavbar();
//       timer = null;
//     }, 300);
//   };
//   const playsound = () => {
//     // const audio = document.getElementById("focusAudio");
//     // audio.play()
//   };
//   const pathName = () => {
//     const nav = Array.from(
//       document.getElementsByClassName("navbar") as HTMLCollectionOf<HTMLElement>
//     );
//     path = window.location.pathname.includes("photos");
//     if (nav[0]) {
//       path ? setTitle(true) : setTitle(false);
//       path
//         ? (nav[0].style.backgroundColor = "unset")
//         : (nav[0].style.backgroundColor = "transparent");
//     }
//   };
//   useEffect(() => {
//     // window.addEventListener("resize", showImg);
//     window.addEventListener("scroll", throttledCompactnavbar);
//     // compactnavbar();
//     pathName();
//   }, [path]);
//   const updateStuff = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//     // closeMobileMenu();
//     handleClick();
//     setTitle(false);
//   };
//   return (
//     <>
//       <span
//         style={{ display: !title ? "none" : "inline-block" }}
//         className={
//           scrolled ? "photoGalleryTitle scrolled" : "photoGalleryTitle"
//         }
//       >
//         {title ? path : ""}
//       </span>
//       {/* <audio style={{ display: 'none' }} id="focusAudio" src={focusinout} controls autoPlay={true} /> */}
//       <nav
//         className={
//           !compactNavMenu ? `navbar ${navClass}` : `navbar ${navClass} compact`
//         }
//       >
//         <div
//           className="navbar-container"
//           style={
//             click
//               ? { background: darkTheme ? "black" : "white" }
//               : { background: "transparent" }
//           }
//         >
//           <Link
//             to="/"
//             className={click ? "navbar-logo mobilemenu-active" : "navbar-logo"}
//             onClick={updateStuff}
//           >
//             <span onMouseEnter={playsound} className="navbar-logo-text">
//               Its_in_Focus
//             </span>
//           </Link>
//           <div className="menu-icon" onClick={handleClick}>
//             <FontAwesomeIcon
//               icon={click ? ["fas", "times"] : ["fas", "bars"]}
//             ></FontAwesomeIcon>
//           </div>
//           <ul className={click ? "nav-menu activeMenu" : "nav-menu"}>
//             {/* <li className="nav-item">
//                             <NavLink to="/" className="nav-links" onClick={updateStuff} end>
//                                 <span>home</span>
//                             </NavLink>
//                         </li> */}
//             <li className="nav-item">
//               <NavLink
//                 to="/"
//                 exact
//                 className="nav-links"
//                 onClick={updateStuff}
//                 end
//               >
//                 <span>photography</span>
//               </NavLink>
//             </li>
//             {/* <li className="nav-item">
//                             <Link to="/services" className="nav-links" onClick={closeMobileMenu}>Services</Link>
//                         </li> */}
//             <li className="nav-item">
//               <NavLink
//                 to="/blogs"
//                 exact
//                 className="nav-links"
//                 onClick={updateStuff}
//               >
//                 <span> blog</span>
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink
//                 to="/gear"
//                 exact
//                 className="nav-links"
//                 onClick={updateStuff}
//               >
//                 <span> gear</span>
//               </NavLink>
//             </li>
//             {/* <li className="nav-item">
//                             <NavLink to="/submitpost" activeClassName="active" className="nav-links" onClick={updateStuff}>
//                                 post</NavLink>
//                         </li> */}
//             {/* <li className="nav-item">
//                             <NavLink to="/resume" className="nav-links" onClick={updateStuff}>
//                                 <span>resume</span>
//                             </NavLink>
//                         </li> */}

//             <li className="nav-item">
//               <div className="nav-links">
//                 <Switch
//                   className="darkModeToggle"
//                   checkedChildren={<FontAwesomeIcon icon={["fas", "sun"]} />}
//                   unCheckedChildren={<FontAwesomeIcon icon={["fas", "moon"]} />}
//                   defaultChecked={!getThemeContext}
//                   onChange={toggleTheme}
//                 />
//               </div>
//             </li>
//             {<div className="mobile-menu-svg"></div>}
//           </ul>

//           {/* { button && <Button buttonStyle="btn--outline">SIGN UP</Button>} */}
//         </div>
//         <div className="color-div"></div>
//       </nav>
//     </>
//   );
// }

// export default Navbar;

import React, { useState, useEffect, useContext } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './Navbar.sass';
import { useTheme, useThemeUpdate } from '../Themecontext/themeContext';
import { Dropdown, Menu, Switch } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from './pages/loginContainer/AuthComponent';
import { handleLogin, handleLogout } from './helper/fetchUser';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { logoNoBg, logoNoBgWhite } from '../assets';

interface NavbarProps {
  onClick?: () => void | undefined;
  className: string;
}

const list = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const item = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -100 },
};

// works when <4.24.0, deprecated when >=4.24.0 🙅🏻‍♀️ Antd
const LoginMenu = (user: any, updateStuff: any) => {
  const navigate = useNavigate();
  return (
    <Menu>
      {!user && (
        <Menu.Item className="login-logout-menu" onClick={handleLogin} key="login">
          <span className="ml-1">login with google</span>
          <FontAwesomeIcon icon={['fas', 'sign-in-alt']}></FontAwesomeIcon>
        </Menu.Item>
      )}
      {user && (
        <>
          <Menu.Item
            key="1"
            className="login-logout-menu"
            onClick={() => {
              updateStuff();
              navigate('/login');
            }}
          >
            <span className="ml-1 w-75">Profile</span>
            <FontAwesomeIcon
              className="w-25"
              icon={['fas', 'user']}
            ></FontAwesomeIcon>
          </Menu.Item>
          <Menu.Item
            className="login-logout-menu"
            onClick={handleLogout}
            key="2"
          >
            <span className="ml-1 w-75">Logout</span>
            <FontAwesomeIcon
              className="w-25"
              icon={['fas', 'sign-out-alt']}
            ></FontAwesomeIcon>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};

const Navbar: React.FC<NavbarProps> = ({ onClick }) => {
  const [click, setClick] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [compactNavMenu, setCompactNavMenu] = useState(false);
  const getThemeContext = localStorage.getItem('theme_context') === 'true';
  const darkTheme = useTheme(getThemeContext);
  const toggleTheme = useThemeUpdate();
  const location = useLocation();
  // const [showimg, showImage] = useState(false);
  const [title, setTitle] = useState(false);
  const [navClass, updateNavClass] = useState('home');

  useEffect(() => {
    updateNavClass(location.pathname.split('/').pop() || 'home');
  }, [location.pathname, darkTheme]);

  const handleClick = () => {
    if (window.innerWidth < 768) {
      if (!click) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
      } else {
        document.body.style.overflow = 'auto';
        document.body.style.position = 'relative';
      }
      setClick(!click);
    }
  };
  //   let count = 0;
  //   let scrollTimer: NodeJS.Timeout | null = null;
  //   const compactnavbar = () => {
  //     if (scrollTimer !== null) {
  //       clearTimeout(scrollTimer);
  //     }

  //     scrollTimer = setTimeout(() => {
  //       // Implement the logic for compacting the navbar
  //       // console.log("compacting navbar :" + ++count);
  //       const nav = Array.from(
  //         document.getElementsByClassName(
  //           "navbar"
  //         ) as HTMLCollectionOf<HTMLElement>
  //       );
  //       const typeTitle = Array.from(
  //         document.getElementsByClassName(
  //           "photoGalleryTitle"
  //         ) as HTMLCollectionOf<HTMLElement>
  //       );
  //       const footer = document.getElementsByClassName("footer");

  //       if (document.documentElement.scrollTop > 80) {
  //         if (!nav[0]?.classList.contains("mobilemenu")) setScrolled(true);
  //         if (window.innerWidth > 960) setCompactNavMenu(true);
  //         if (!nav[0]?.classList.contains("mobilemenu")) {
  //           nav[0].classList.add("mobilemenu");
  //         }
  //       } else {
  //         // if (!compactNavMenu) {
  //         if (nav[0]?.classList.contains("mobilemenu")) setScrolled(false);
  //         if (window.innerWidth > 960) setCompactNavMenu(false);
  //         if (nav[0]?.classList.contains("mobilemenu")) {
  //           nav[0].classList.remove("mobilemenu");
  //         }
  //       }
  //       if (nav[0]) {
  //         if (
  //           (footer[0] && footer[0].getBoundingClientRect().top < 10) ||
  //           location.pathName === "Playaround"
  //         ) {
  //           nav[0].classList.add("h-0");
  //           typeTitle[0].style.left = "-35px";
  //         } else {
  //           nav[0].classList.remove("h-0");
  //           typeTitle[0].style.left = "28px";
  //         }
  //       }
  //       scrollTimer = null;
  //     }, 15);
  //   };

  //   useEffect(() => {
  //     window.addEventListener("scroll", compactnavbar);
  //     return () => {
  //       window.removeEventListener("scroll", compactnavbar);
  //       if (scrollTimer !== null) {
  //         clearTimeout(scrollTimer);
  //       }
  //     };
  //   }, []);
  const userDetails = useContext(AuthContext);
  const user = userDetails?.user;
  const databaseUser = userDetails?.databaseUser;
  const navigate = useNavigate();

  // const pathName = () => {

  // };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        // setScrolled(true);
        // pathName();
        // pathname code moved to useEffect
        const nav = Array.from(
          document.getElementsByClassName(
            'navbar'
          ) as HTMLCollectionOf<HTMLElement>
        );
        if (nav[0]) {
          // location.pathname ? setTitle(true) : setTitle(false);
          location.pathname
            ? (nav[0].style.backgroundColor = 'unset')
            : (nav[0].style.backgroundColor = 'transparent');
        }
        // Till here
        const footerElement = document.getElementsByClassName('footer')[0];
        const navElement = Array.from(
          document.getElementsByClassName(
            'navbar'
          ) as HTMLCollectionOf<HTMLElement>
        )[0];
        const typeTitle = Array.from(
          document.getElementsByClassName(
            'row-gallery-title'
          ) as HTMLCollectionOf<HTMLElement>
        )[0];
        if (
          typeTitle &&
          footerElement &&
          footerElement.getBoundingClientRect().top < 10
        ) {
          navElement.classList.add('h-0');
          setTimeout(() => {
            typeTitle.style.left = '-35px';
          }, 0);
        } else {
          navElement.classList.remove('h-0');
          // typeTitle.style.left = "100px";
        }

        // if (window.innerWidth > 960) {
        setCompactNavMenu(true);
        // }
      } else {
        setScrolled(false);
        setCompactNavMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathName, location.pathname, title, darkTheme]);

  const updateStuff = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // closeMobileMenu();
    handleClick();
    setTitle(false);
    localStorage.removeItem('newBlogSavedData');
  };

  return (
    <>
      {/* <span
        style={{
          display:
            title &&
            location.pathname.split("/").pop() !== "" &&
            location.pathname.includes("photos")
              ? "inline-block"
              : "none",
        }}
        className={
          scrolled ? "photoGalleryTitle scrolled" : "photoGalleryTitle"
        }
      >
        {title ? location.pathname.split("/").pop() : ""}
      </span> */}
      {/* <audio style={{ display: 'none' }} id="focusAudio" src={focusinout} controls autoPlay={true} /> */}
      <nav
        className={
          !compactNavMenu ? `navbar ${navClass}` : `navbar ${navClass} compact`
        }
      >
        <div
          className="navbar-container"
          style={
            click
              ? { background: darkTheme ? 'black' : 'white' }
              : { background: 'transparent' }
          }
        >
          <Link
            to="/"
            exact
            key={'home'}
            className={click ? 'navbar-logo mobilemenu-active' : 'navbar-logo'}
            // onClick={updateStuff}
          >
            <span className="navbar-logo-text">
              <img
                src={compactNavMenu || darkTheme ? logoNoBgWhite : logoNoBg}
                alt="logo"
              />
            </span>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <FontAwesomeIcon
              icon={click ? ['fas', 'times'] : ['fas', 'bars']}
            ></FontAwesomeIcon>
          </div>

          <motion.ul
            initial="hidden"
            animate="visible"
            variants={list}
            className={click ? 'nav-menu activeMenu' : 'nav-menu'}
          >
            {/* <li className="nav-item">
                                <NavLink to="/" className="nav-links" onClick={updateStuff} end>
                                    <span>home</span>
                                </NavLink>
                            </li> */}
            <div className="nav-links-mobile">
              <Link
                key={'home'}
                to="/"
                className={
                  click ? 'navbar-logo mobilemenu-active' : 'navbar-logo'
                }
                onClick={updateStuff}
              >
                <span className="navbar-logo-text">
                  <img
                    src={compactNavMenu || darkTheme ? logoNoBgWhite : logoNoBg}
                    alt="logo"
                  />
                </span>
              </Link>
              <div className="menu-icon" onClick={handleClick}>
                <FontAwesomeIcon
                  icon={click ? ['fas', 'times'] : ['fas', 'bars']}
                ></FontAwesomeIcon>
              </div>
            </div>
            <motion.li variants={item} className="nav-item">
              <NavLink
                to="/"
                exact
                className="nav-links"
                onClick={updateStuff}
                end
              >
                <span>photography</span>
              </NavLink>
            </motion.li>
            {/* <li className="nav-item">
                                <Link to="/services" className="nav-links" onClick={closeMobileMenu}>Services</Link>
                            </li> */}
            <motion.li variants={item} className="nav-item">
              <NavLink
                to="/blogs"
                exact
                className="nav-links"
                onClick={updateStuff}
              >
                <span> blog</span>
              </NavLink>
            </motion.li>
            <motion.li variants={item} className="nav-item">
              <NavLink
                to="/gear"
                exact
                className="nav-links"
                onClick={updateStuff}
              >
                <span> gear</span>
              </NavLink>
            </motion.li>
            {user && databaseUser?.accessList?.editorAccess ? (
              <motion.li variants={item} className="nav-item">
                <NavLink
                  to="/editor"
                  activeclassname="active"
                  className="nav-links"
                  onClick={updateStuff}
                >
                  editor
                </NavLink>
              </motion.li>
            ) : null}
            {user && (
              <motion.li
                variants={item}
                className="nav-item nav-links-mobile profile"
                onClick={() => {
                  updateStuff();
                  navigate('/login');
                }}
              >
                <div className="nav-links">
                  <span className="ml-1 w-75">profile</span>
                </div>
              </motion.li>
            )}
            <motion.li
              variants={item}
              className="nav-item logout-link nav-links-mobile"
              onClick={user ? handleLogout : handleLogin}
            >
              <span className="nav-links">
                {user ? 'logout' : 'login with google'}
              </span>
              {/* <FontAwesomeIcon
                      className="w-25"
                      icon={["fas", "sign-out-alt"]}
                    ></FontAwesomeIcon> */}
            </motion.li>
            {/* <li className="nav-item">
                                <NavLink to="/resume" className="nav-links" onClick={updateStuff}>
                                    <span>resume</span>
                                </NavLink>
                            </li> */}

            <motion.li variants={item} className="nav-item">
              <div className="nav-links">
                <Switch
                  className="darkModeToggle"
                  checkedChildren={<FontAwesomeIcon icon={['fas', 'sun']} />}
                  unCheckedChildren={<FontAwesomeIcon icon={['fas', 'moon']} />}
                  defaultChecked={!getThemeContext}
                  onChange={() => {
                    toggleTheme();
                    updateStuff();
                  }}
                />
              </div>
            </motion.li>

            <Dropdown
              className="profile-dropdown"
              overlay={LoginMenu(user, updateStuff)}
              overlayClassName="login-dropdown"
              placement="bottomRight"
              mouseEnterDelay={0}
            >
              <motion.li variants={item} className="nav-item">
                <div className="nav-links py-0">
                  {user ? (
                    <img
                      className="nav-profile-picture"
                      src={user.photoURL as string}
                      alt="Profile"
                      referrerPolicy="no-referrer"
                    />
                  ) : (
                    <FontAwesomeIcon icon={['fas', 'user']}></FontAwesomeIcon>
                  )}
                </div>
              </motion.li>
            </Dropdown>
            {<div className="mobile-menu-svg"></div>}
            {/* <motion.li
              variants={item}
              className="profile-menu nav-links-mobile"
            >
              {user && (
                <div className="loggedin-mobile-menu">
                  <div
                    className="login-logout-menu"
                    onClick={() => navigate("/login")}
                  >
                    <img
                      className="nav-profile-picture"
                      src={user.photoURL as string}
                      alt="Profile"
                      referrerPolicy="no-referrer"
                    />
                    <span className="ml-1 w-75">{user.displayName}</span>
                  </div>
                </div>
              )}
            </motion.li> */}
          </motion.ul>

          {/* { button && <Button buttonStyle="btn--outline">SIGN UP</Button>} */}
        </div>

        <div className="color-div"></div>
      </nav>
    </>
  );
};

export default Navbar;
