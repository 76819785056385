import React, { memo, useContext, useEffect, useRef, useState } from "react";
import "./Card.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../loginContainer/AuthComponent";
import firebase from "firebase/app";
import "firebase/database";
import { motion } from "framer-motion";

// wait: any, hoverEnabled: any, transitionDuration: any, imgData: any
let clickCount = 0;
let clickTimer: any = null;
let lastTapTime = 0;

const Card: React.FC<{
  wait: number;
  transitionDuration: number;
  hoverEnabled: boolean;
  imgData: any;
  type: string;
  editMode: boolean;
  imgUID: string
}> = ({ wait, transitionDuration, hoverEnabled, imgData, type, editMode, imgUID }) => {
  const userDetails = useContext(AuthContext);
  const user = userDetails?.user;
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgLiked, setImgLiked] = useState(
    imgData.likes?.likedBy?.find((item: any) => item.userId === user?.uid)
  );
  const [likeCount, setLikeCount] = useState(imgData.likes.totalLikes);
  const doubleTapRef = useRef<HTMLDivElement>(null);
  const heartLikeRef = useRef<any>(null);

  const handleLikePhoto = (liked: boolean, imgprops: any) => {
    var photoContainerRef = firebase
      .database()
      .ref(
        `PhotoContainer/${type}/images/${imgUID}/likes`
      );
    photoContainerRef.once("value").then((snapshot) => {
      const photoLikesObject = snapshot.val();
      //   photoLikesObject.totalLikes = liked
      //     ? photoLikesObject.totalLikes + 1
      //     : photoLikesObject.totalLikes - 1 < 0 ? 0 : photoLikesObject.totalLikes - 1;
      if (!photoLikesObject.likedBy) photoLikesObject.likedBy = [];
      const alreadyLiked = photoLikesObject.likedBy.find(
        (item: any) => item.userId === user?.uid
      );
      if (liked && !alreadyLiked) {
        photoLikesObject.likedBy.push({
          userId: user?.uid,
          userName: user?.displayName,
        });
        setImgLiked(true);
      } else {
        photoLikesObject.likedBy = photoLikesObject.likedBy.filter(
          (item: any) => item.userId !== user?.uid
        );
        setImgLiked(false);
      }
      photoLikesObject.totalLikes = photoLikesObject.likedBy.length;
      setLikeCount(photoLikesObject.totalLikes);
      photoContainerRef
        .set(photoLikesObject)
        .then(() => {
          // console.log("Likes updated successfully in the database.");
        })
        .catch((error) => {
          console.error("Error updating likes in the database:", error);
        });
    });
  };

  useEffect(() => {
    const isTouchDevice = "ontouchstart" in window;
    if (!isTouchDevice) {
      const element = doubleTapRef.current;
      element?.addEventListener("click", handleDoubleClick);
      return () => {
        if (element) element.removeEventListener("click", handleDoubleClick);
      };
    }
  }, []);

  const handleDoubleTouch = () => {
    const currentTime = new Date().getTime();
    const tapTimeDifference = currentTime - lastTapTime;

    if (tapTimeDifference < 300) {
      // Double tap detected
      handleLikePhoto(!imgLiked, imgData);
    }

    lastTapTime = currentTime;
  };

  const handleDoubleClick = () => {
    clickCount++;

    if (clickCount === 1) {
      clickTimer = setTimeout(function () {
        clickCount = 0;
      }, 300);
    } else if (clickCount === 2) {
      clearTimeout(clickTimer);
      clickCount = 0;
      handleLikePhoto(!imgLiked, imgData);
    }
  };

  return (
    <div
      ref={doubleTapRef}
      className={`double-tap-like ${imgUID}`}
      //   onClick={handleDoubleClick}
      onTouchStart={handleDoubleTouch}
      key={`card-double-tap-like-${imgUID}`}
    >
      <div className={`imagewrapper ${editMode ? (imgData.isActive ? 'edit-mode' : 'edit-mode inActive') : ''}`}>
        {/* <div className={this.props.hoverEnabled ? "card-container hoverEnabled" : "card-container"} id={this.props.img}>
          <div className="card" style={style}></div>
          <span className="photoType">{this.props.photoType}</span>
      </div> */}
        {user && !editMode && (
          <motion.div className="likes-container">
            <span className="like-count">
              {likeCount === 1 ? "1 like" : `${likeCount} likes`}
            </span>

            <FontAwesomeIcon
              className={`like-photo-icon ${!imgLiked ? "d-block" : "d-none"}`}
              onClick={() => handleLikePhoto(true, imgData)}
              icon={["far", "heart"]}
            ></FontAwesomeIcon>
            <FontAwesomeIcon
              ref={heartLikeRef}
              className={`like-photo-icon fill ${
                imgLiked ? "d-block heartBeat" : "d-none"
              }`}
              onClick={() => handleLikePhoto(false, imgData)}
              icon={["fas", "heart"]}
            />
          </motion.div>
        )}

        <img
          src={imgData.imgURL}
          style={imgLoaded ? { opacity: 1 } : {}}
          onLoad={() => setImgLoaded(true)}
          alt=""
        />
      </div>
    </div>
  );
};

export default Card;

// class Card extends React.Component<
//   any,
//   {
//     imgLoaded: boolean;
//     imgLiked: boolean;
//     likeCount: number;
//   }
// > {
//   componentMounted: boolean;

//   constructor(props: any) {
//     super(props);
//     this.state = {
//       imgLoaded: false,
//       imgLiked: false,
//       likeCount: 0,
//     };
//     this.componentMounted = false;
//     // setTimeout(function () {
//     //     that.show();
//     // }, that.props.wait);
//   }
//   getInitialState() {
//     return { hidden: "hidden" };
//   }
//   componentDidMount() {
//     this.componentMounted = true;
//   }
//   // show() {
//   //     if (this.componentMounted) {
//   //         this.setState({ hidden: 'visibleNow' });
//   //     }
//   // };
//   render() {
//     // const heightType = this.props.height ? this.props.height.replace(/[0-9]/g, "") : "px";
//     // const height = this.props.height ? this.props.height.replace(/[^0-9]/g, "") : 300;
//     // var width;
//     // const background = this.props.imgURL;
//     // const img = new Image();
//     // img.src = background;
//     // img.onload = () => {
//     //     const aspectRatio = img.width / img.height;
//     //     width = aspectRatio * height + heightType;
//     //     setTimeout(() => {
//     //         const element = document.getElementById(this.props.img);
//     //         if (element) {
//     //             element.style.height = "500px";
//     //             element.style.opacity = "1";
//     //             element.style.width = element.clientHeight * aspectRatio + "px";
//     //         }
//     //         // element.style.height = element.clientWidth/aspectRatio + 'px'
//     //         // element.style.maxWidth = (90 / aspectRatio) + 'vh';
//     //     }, 0);
//     // };
//     // const style = {
//     //     backgroundImage: `url(${this.props.imgURL})`,
//     //     backgroundSize: "cover",
//     //     backgroundRepeat: "no-repeat",
//     //     width: "",
//     //     // height: '300px',
//     //     paddingTop: `percentage(image-height(${this.props.imgURL})/image-width(${this.props.imgURL}))`,
//     // };

//     return <UserContext.Consumer>{(contextValue) => {}}</UserContext.Consumer>;
//   }
// }
// export default memo(Card);
