// import React, { Component } from "react";
// import ReactDOM from "react-dom";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Editor.sass';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import firebase from 'firebase/app';
import {
  Button,
  Input,
  InputRef,
  MessageArgsProps,
  Upload,
  message,
} from 'antd';
import { AuthContext } from '../loginContainer/AuthComponent';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

const messageConfig: MessageArgsProps = {
  className: 'snack-bar-message',
  content: '',
  duration: 3,
};

const MyEditor = () => {
  const userDetails = useContext(AuthContext);
  const user = userDetails?.user;
  const databaseUser = userDetails?.databaseUser;
  const navigate = useNavigate();
  const savedBlogData = JSON.parse(
    localStorage.getItem('newBlogSavedData') as string
  );
  const location = useLocation();
  let blogEditDetails = location?.state?.query;
  let keyForBlogUpdating = location?.state?.query?.keyProp;

  const savedFileList = JSON.parse(
    localStorage.getItem('fileListBlog') as string
  );
  const [editorState, setEditorState] = useState(
    savedBlogData && !blogEditDetails
      ? EditorState.createWithContent(stateFromHTML(savedBlogData.content))
      : blogEditDetails
      ? EditorState.createWithContent(stateFromHTML(blogEditDetails.content))
      : EditorState.createEmpty()
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [selectedImage, setSelectedImage] = useState<Blob | null>(null);
  const [selectedImageURL, setSelectedImageURL] = useState(
    savedBlogData && !blogEditDetails
      ? savedBlogData?.image
      : blogEditDetails
      ? blogEditDetails.image
      : ''
  );
  const [title, setTitle] = useState(
    savedBlogData && !blogEditDetails
      ? savedBlogData?.title
      : blogEditDetails
      ? blogEditDetails.title
      : ''
  );
  const [summary, setSummary] = useState(
    savedBlogData && !blogEditDetails
      ? savedBlogData?.summary
      : blogEditDetails
      ? blogEditDetails.summary
      : ''
  );
  var storageRef = firebase.storage().ref();
  const fileInputRef = useRef<InputRef | null>(null);
  const [fileList, setFileList] = useState<any[]>(savedFileList);
  // const location = useLocation();

  const handleImageUpload = (file: any) => {
    return new Promise((resolve, reject) => {
      var folderRef = storageRef.child('blogImages');
      var ref = folderRef.child(`${file.name}`);
      ref.put(file).then(
        (snapshot) => {
          // // console.log(snapshot);
          // // console.log("Uploaded a blob or file!");
          snapshot.ref.getDownloadURL().then((downloadURL: any) => {
            resolve({ data: { link: downloadURL } });
          });
        },
        (error: any) => {
          // Handle unsuccessful uploads
          console.log('Error uploading file', error);
        }
      );
    });
  };

  const toolbarOptions: any = {
    image: {
      className: 'editor-image',
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: 'LEFT',
      
      uploadCallback: handleImageUpload,
      previewImage: false,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: '200px',
        width: 'auto',
      },
    },
  };

  useEffect(() => {
    setTimeout(() => {
      if (!user || !databaseUser?.accessList?.editorAccess) {
        navigate('/login');
      }
    }, 1000);
    return () => {
      setTimeout(() => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const htmlText = draftToHtml(rawContentState);
        // // console.log(htmlText);
        // const jsonText = JSON.stringify(htmlText);
        const newPost = {
          comments: [],
          content: htmlText,
          image: selectedImageURL,
          title: title,
          postTime: new Date().getTime(),
          isActive: true,
          summary: summary,
          postedBy: user?.displayName,
          postedByEmail: user?.email,
          postedByProfilePicture: user?.photoURL,
          postedByUserId: user?.uid,
        };
        // console.log(newPost)
        if (blogEditDetails) {
          localStorage.setItem('newBlogSavedData', JSON.stringify(newPost));
          localStorage.setItem('fileListBlog', JSON.stringify(fileList));
        }
      }, 300);
    };
  }, [navigate, user, title, summary, selectedImageURL, editorState, fileList]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    type === 'title'
      ? setTitle(event.target.value)
      : setSummary(event.target.value);
  };

  const handleEditorChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const uploadFileToFirebase = (file: File) => {
    return new Promise<void>((resolve, reject) => {
      var folderRef = storageRef.child('blogImages');
      var ref = folderRef.child(`${file.name}`);
      ref.put(file).then(
        (snapshot) => {
          // // console.log(snapshot);
          // // console.log("Uploaded a blob or file!");
          snapshot.ref.getDownloadURL().then((downloadURL: any) => {
            setSelectedImageURL(downloadURL);
          });
        },
        (error: any) => {
          // Handle unsuccessful uploads
          // console.log("Error uploading file", error);
        }
      );
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  const uploadAction = async ({ file, onSuccess, onError }: any) => {
    // // console.log(file);
    // setSelectedImage(file);
    try {
      await uploadFileToFirebase(file);
      onSuccess('', file);
      setFileList([...fileList, file]);
    } catch (error) {
      onError(error);
      console.error('Upload failed:', error);
    }
  };

  const convertContentToHtml = () => {
    setButtonLoading(true);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlText = draftToHtml(rawContentState);
    // // console.log(htmlText);
    // const jsonText = JSON.stringify(htmlText);
    const newPost = {
      comments: [],
      content: htmlText,
      image: selectedImageURL,
      title: title,
      postTime: new Date().getTime(),
      isActive: true,
      summary: summary,
      postedBy: user?.displayName,
      postedByEmail: user?.email,
      postedByProfilePicture: user?.photoURL,
      postedByUserId: user?.uid,
    };
    const blogPostRef = firebase.database().ref(`blogPosts`);
    if (!blogEditDetails) {
      blogPostRef.push(newPost, (error: any) => {
        setButtonLoading(false);
        if (error) {
          message.error('Some error occurred!');
          alert('Data could not be saved.' + error);
        } else {
          message.success('Submitted Successfully');
          clearForm();
          localStorage.removeItem('newBlogSavedData');
        }
      });
    } else {
      blogPostRef
        .child(keyForBlogUpdating)
        .set(newPost)
        .then(() => {
          messageConfig.content = 'Blog updated successfully.';
          message.success(messageConfig);
          localStorage.removeItem('newBlogSavedData');
          navigate('/blogs');
          clearForm();
        })
        .catch((error) => {
          messageConfig.content = 'Error updating the blog!';
          message.error(messageConfig);
        });
    }
  };

  const handleClearInput = () => {
    setFileList([]); // Reset the fileList to clear the input field
    if (fileInputRef.current) {
      (fileInputRef.current as any).fileList.pop(); // Reset the input value directly (if needed)
    }
  };

  const clearForm = () => {
    setSummary('');
    setTitle('');
    setSelectedImageURL('');
    setEditorState(EditorState.createEmpty());
    // setSelectedImage(null);
    handleClearInput();
    localStorage.removeItem('newBlogSavedData');
    localStorage.removeItem('fileListBlog');
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="myeditor-container container-fluid overflow-hidden p-0"
    >
      <div className="top-container">
        <div className="h-100 row blog-container">
          <span className="disclaimer-text">* No pressure</span>
          <div className="img-container">
            {/* <div className="img"></div> */}
            <div className="text-blog-headline">
              Let's <br />
              Write
              <br />
              Something<span className="asterisk">*</span>
            </div>
          </div>
        </div>
      </div>
      <div className="editor-container mt-5">
        <div className="title-input-container">
          <h4 className="editor-title">Title</h4>
          <Input
            placeholder="Enter title here"
            className="title-input"
            value={title}
            onChange={(event) => handleInputChange(event, 'title')}
          />
        </div>
        <div className="summary-input-container">
          <h4 className="editor-summary">Summary</h4>
          <Input
            placeholder="Enter Summary here"
            className="title-input"
            value={summary}
            onChange={(event) => handleInputChange(event, 'summary')}
          />
        </div>
        <div className="image-input-container">
          <h4 className="editor-image">Image</h4>
          <Upload
            customRequest={uploadAction}
            // onChange={handleImageChangeAntD}
            ref={fileInputRef}
            listType="picture"
            maxCount={1}
            fileList={fileList}
          >
            <Button
              icon={
                <FontAwesomeIcon
                  className="mr-2"
                  icon={['fas', 'upload']}
                ></FontAwesomeIcon>
              }
            >
              Upload
            </Button>
          </Upload>
          {selectedImageURL && (
            <img className="blog-image" src={selectedImageURL} alt="Selected" />
          )}
        </div>

        {/* <Input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={handleImageChange}
          allowClear
        />
        {selectedImage && (
          <img
            className="blog-image"
            src={URL.createObjectURL(selectedImage)}
            alt="Selected"
          />
        )} */}
        {/* <button className="clear-uploaded-image" onClick={() => clearForm()}>
          Clear Image
        </button> */}
        <div className="content-input-container">
          <h3 className="editor-content-title">Content</h3>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            // onContentStateChange={onContentStateChange}
            wrapperClassName="editor-wrapper"
            editorClassName="editor-content"
            toolbarClassName="editor-toolbar"
            toolbar={toolbarOptions}
          />
        </div>

        <button
          className="btn save-to-firebase mt-3 save-blog"
          onClick={convertContentToHtml}
          disabled={
            title === '' ||
            summary === '' ||
            editorState.getCurrentContent().getPlainText() === '' ||
            buttonLoading
          }
        >
          {blogEditDetails ? 'Update Blog' : 'Save Blog'}
        </button>
      </div>
    </motion.div>
  );
};

export default MyEditor;
